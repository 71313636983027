// グロナビの中身はこのdataオブジェクトで管理している(PC・SP別)
const getContentsPc = [
  {
    id: 1,
    name: "火災保険見積もり",
    pcOnlyNavUrl: "/",
    // アコーディオンを使いたい場合は accordionDisplayをtrueにする
    accordionDisplay: true,
    gaId: "globalNav_button_9",
    content: [
      // アコーディオンの中身はcontentの配列の中で設定する
      {
        name: "個人の方",
        url: "/",
        blank: false,
        gaId: "globalNav_button_1",
      },
      {
        name: "法人の方",
        url: "/hojin/step/step1.html",
        blank: false,
        gaId: "globalNav_button_2",
      },
      {
        name: "賃貸物件の方",
        url: "https://www.zurichssi.co.jp/kazai-lp/?utm_medium=sdzp&utm_source=siwcad&utm_campaign=bn&utm_content=common&ssi_agencyCode=000",
        blank: true,
        gaId: "globalNav_button_3",
      },
    ],
  },
  {
    // アコーディオンを使わない場合は下記オブジェクトを参考に
    id: 2,
    name: "火災保険ランキング",
    url: "/ranking/",
    accordionDisplay: false,
    blank: false,
    gaId: "globalNav_button_4",
    content: [],
  },
  {
    id: 3,
    name: "火災保険について",
    pcOnlyNavUrl: "/cate/basic/",
    accordionDisplay: true,
    gaId: "globalNav_button_10",
    content: [
      {
        name: "火災保険の相場",
        url: "/cont/basic-rate/",
        blank: false,
        gaId: "globalNav_button_5",
      },
      {
        name: "新築住宅について",
        url: "/cont/new-construction/",
        blank: false,
        gaId: "globalNav_button_13",
      },
      {
        name: "中古住宅について",
        url: "/cont/choose-cyukojutaku/",
        blank: false,
        gaId: "globalNav_button_6",
      },
      {
        name: "住宅ローンと火災保険",
        url: "/cont/choose-loan/",
        blank: false,
        gaId: "globalNav_button_7",
      },
    ],
  },
  {
    id: 4,
    name: "地震保険について",
    pcOnlyNavUrl: "/cate/jishin-basic/",
    accordionDisplay: true,
    gaId: "globalNav_button_11",
    content: [
      {
        name: "マンション",
        urlPC: "/cont/jishin-mansion/",
        url: "/cont/jishin-mansion/",
        blank: false,
        gaId: "globalNav_button_8",
      },
    ],
  },
];
const getContentsSp = [
  {
    id: 1,
    name: "火災保険見積もり",
    accordionDisplay: true,
    content: [
      {
        name: "個人の方",
        url: "/",
        blank: false,
        gaId: "globalNav_button_1",
      },
      {
        name: "法人の方",
        url: "/hojin/step/step1.html",
        blank: false,
        gaId: "globalNav_button_2",
      },
      {
        name: "賃貸物件の方",
        url: "https://www.zurichssi.co.jp/kazai-lp/?utm_medium=sdzp&utm_source=siwcad&utm_campaign=bn&utm_content=common&ssi_agencyCode=000",
        blank: true,
        gaId: "globalNav_button_3",
      },
    ],
  },
  {
    id: 2,
    name: "火災保険ランキング",
    url: "/ranking/",
    accordionDisplay: false,
    blank: false,
    content: [],
    gaId: "globalNav_button_4",
  },
  {
    id: 3,
    name: "火災保険について",
    accordionDisplay: true,
    content: [
      {
        name: "火災保険とは",
        url: "/cate/basic/",
        blank: false,
        gaId: "globalNav_button_10",
      },
      {
        name: "火災保険の相場",
        url: "/cont/basic-rate/",
        blank: false,
        gaId: "globalNav_button_5",
      },
      {
        name: "新築住宅について",
        url: "/cont/new-construction/",
        blank: false,
        gaId: "globalNav_button_13",
      },
      {
        name: "中古住宅について",
        url: "/cont/choose-cyukojutaku/",
        blank: false,
        gaId: "globalNav_button_6",
      },
      {
        name: "住宅ローンと火災保険",
        url: "/cont/choose-loan/",
        blank: false,
        gaId: "globalNav_button_7",
      },
    ],
  },
  {
    id: 4,
    name: "地震保険について",
    accordionDisplay: true,
    content: [
      {
        name: "地震保険とは",
        url: "/cate/jishin-basic/",
        blank: false,
        gaId: "globalNav_button_11",
      },
      {
        name: "マンション",
        url: "/cont/jishin-mansion/",
        blank: false,
        gaId: "globalNav_button_8",
      },
    ],
  },
];
export { getContentsPc, getContentsSp };

<script setup lang="ts">
import { SiteInformationResults } from "@/types/Facet";

const { $fireBangApiDomain } = useNuxtApp();

// お知らせ取得
const { data: fetchInfodata } = await useFetch(`${$fireBangApiDomain}/api/facet/information`, {
  key:'informationData',
  transform: (data: SiteInformationResults) => {
    const results = data ? data.results : null;
    return results;
  },
});
</script>

<template>
  <div v-if="fetchInfodata?.length" class="button-linkTopics">
    <a
      v-for="(item, index) in fetchInfodata"
      :key="index"
      href="javascript:void(0);"
      @click="$popUp.browserWin(item.url)"
      >{{ item.title }}</a
    >
  </div>
</template>

<style lang="scss" scoped>
.button-linkTopics {
  width: 91.47%;
  margin: 0 auto 4.66%;
  padding: 3.5% 4.66% 4.66% 3.5%;
  border: 1px solid #1E86C6;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;

  a {
    display: table;
    color: $colorBlue;
    font-size: px(12);
    font-weight: bold;
    text-indent: -.5em;
    padding-left: 1em;
    text-decoration: underline;
    &::before {
      content: "・";
      display: inline-block;
      text-decoration: none;
    }
    &::after{
      content: "";
      background: url(~/assets/images/icon_information.png) no-repeat
      center;
      background-size: contain;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: relative;
      top: 4px;
      right: -4px;
    }
    &:not(:first-child) {
      margin: 2.33% 0 0 0;
    }
  }
}

.button-linkTopics a:hover {
  text-decoration: underline;
}

#promo {
  .button-linkTopics {
    margin: 7% 4% -3%;
    padding: 3.5% 4.66% 4.66% 3.5%;
    border: 1px solid #FFF;
    background-color: inherit;
    text-align: left;

    a {
      display: block;
      color: #fff;
      &::after{
        content: "";
        background: url(~/assets/images/icon_information_w.png) no-repeat
        center;
        background-size: contain;
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        top: 4px;
        right: -4px;
      }
    }
  }

  &.pageLanding03Index .button-linkTopics a {
    color: #333;
  }

  .button-linkTopics a:not(:first-child) {
    margin: 0.4em 0 0;
  }
}

@include media(pc) {
  .button-linkTopics {
    // margin: 0.8em auto -27px;
    margin: 24px auto -28px;
    padding: 16px 20px 20px 16px;
    width: $pcWrapWidth;
    box-sizing: border-box;

    a {
      font-size: px(14);

      &:not(:first-child) {
        margin: 8px 0 0 0;
      }
    }
  }

  #promo .button-linkTopics {
    margin: 40px auto -28px;
    padding: 16px 20px 20px 16px;

    a {
      font-size: px(14);
    }
  }
}
</style>
